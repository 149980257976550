import React, { ReactNode } from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { container } from './styles'

type Props = {
    children?: ReactNode
}

const settings = {
    fade: true,
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    pauseOnHover: false
}

const TopSlider: React.VFC<Props> = ({ children, ...props }) => {

    return (
        <Slider {...props} css={container} {...settings}>
            {children}
        </Slider>
    )
}

export default TopSlider