import React from 'react'

import Index from '~/components/pages/Index'
import SEO from '~/utils/seo'
import { PageProps } from 'gatsby'

export default function IndexPage({ location }: PageProps) {
    return (
        <>
            <SEO
                title='Onsen*(オンセン) | 入浴剤と専用アプリで すべてが新しいおふろ体験。'
                description='情報に溢れる現代。Onsen*(オンセン)は自分と向き合うあなたを応援する、入浴剤とアプリの新しい入浴習慣を届けるブランドです。別府温泉発のミネラル入浴剤と、バスライトやBGMを提供する専用アプリとの連携により、自宅の浴室が「疲れを癒すスパ空間」へと変わります。'
                robots='all'
                url={location.href}
            />
            <Index />
        </>
    )
}
