import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const container = css`
	.slick-slide {
		width: 100%;
	}

	.slick-dots {
		bottom: 22px;
		right: 22px;
		width: auto;
		@media (min-width: ${breakpoints.tablet}px) {
            bottom: 40px;
            right: 80px;
		}
	}

	.slick-dots li button:before {
		font-size: 1rem;
		color: ${palette.text.secondary};
		@media (min-width: ${breakpoints.tablet}px) {
			font-size: 1.2rem;
		}
	}

	.slick-dots li.slick-active button:before {
		color: ${palette.common.white};
		opacity: 1;
	}
`