import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'


export const sento__list = {
  contents_text: css`
    width: 100%;

    @media (min-width: ${breakpoints.tablet}px) {
      min-width: 360px;
    }
  `,

  category: {
    main: css`
      display: flex;
      align-items: center;
      gap: 10px;
      margin-bottom: 10px;
      @media (min-width: ${breakpoints.tablet}px) {
        margin-bottom: 20px;
      }
    `,
    text: css`
      font-style: normal;
      font-weight: bold;
      font-size: 2.0rem;
      line-height: 2.6rem;
    `,
    icon: css`
      width: 20px;
      height: 18px;
    `,
  },

  title: {
    main: css`
      display: flex;
      align-items: center;

      font-style: normal;
      font-weight: bold;
      font-size: 3.2rem;
      line-height: 4.0rem;

      color: ${palette.text.primary};
      @media (max-width: ${breakpoints.tablet}px) {
        font-size: 2.8rem;
        line-height: 3.6rem;
      }
    `,
    beta: css`
      margin-left: 16px;

      font-style: normal;
      font-weight: bold;
      font-size: 1.6rem;
      line-height: 4.0rem;

      color: ${palette.text.secondary};
      @media (max-width: ${breakpoints.tablet}px) {
        display: none;
      }
    `,
  },

  regions: css`
    padding: 0 20px;
    @media (min-width: ${breakpoints.tablet}px) {
      padding: 0 40px;
    }

    max-width: calc(50% + 500px);
    margin-left: auto;
    @media (min-width: 1000px) {
      padding: 0;
    }

    display: flex;
    align-items: center;
    gap: 8px;

    margin-top: 20px;
    margin-bottom: 20px;
    overflow-x: auto;
    white-space: nowrap;
  `,

  region: {
    main: css`
      padding: 12px 40px;
      border-radius: 29px;
      color: ${palette.grey['900']};

      :hover {
        cursor: pointer;
        background: ${palette.grey['500']};
        color: ${palette.grey['800']};
      }

      font-style: normal;
      font-weight: bold;
      font-size: 2.0rem;
      line-height: 2.6rem;
      white-space: nowrap;

      @media (max-width: ${breakpoints.tablet}px) {
        // phone
        padding: 13px 36px;
        font-size: 1.8rem;
        line-height: 1.8rem;
      }
    `,
    active: css`
      background: ${palette.grey['500']};
      color: ${palette.grey['800']};
    `,
  },
}

export const sento__list__slider = {
  wrapper: css`
    padding: 0 20px;

    height: 100%;
    width: 120%;
    margin-left: -10%;
    
    @media (max-width: ${breakpoints.pc}px) {
      // tablet
      width: 140%;
      margin-left: -20%;
    }
    
    @media(max-width: ${breakpoints.tablet}px) {
      // phone
      padding: 0 40px;

      width: 220%;
      margin-left: -60%;
    }

    @media (max-width: 420px) {
      // small phone
      width: 260%;
      margin-left: -80%;
    }
    
    
  `,

  item: css`
    height: 100%;
    
    font-weight: 400;
    color: ${palette.common.black};

    border: 1px solid ${palette.grey['300']};
    border-radius: 5px;
    box-sizing: border-box;
    
    position: relative;
    
    :hover {
      border: 1px solid ${palette.primary.main};
      opacity: 90%;
    }
  `,

  content: css`
    padding: 10px 11px 0 13px;
    position: relative;
    height: 180px;
  `,

  category: {
    main: css`
      display: flex;
      flex-direction: row;
      gap: 5px;

      font-style: normal;
      font-weight: normal;
      font-size: 1.0rem;
      line-height: 1.6rem;
      color: ${palette.common.black};
    `,
    sauna: css`
      font-weight: bold;
      color: ${palette.primary.main};
    `,
  },

  title: css`
    margin: 3px 0;

    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: ${palette.text.primary};
  `,

  address: css`
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.6rem;

    color: ${palette.grey['800']};
  `,

  closeDay: css`
    font-style: normal;
    font-weight: normal;
    font-size: 1.1rem;
    line-height: 1.6rem;
    margin-top: 3px;

    color: ${palette.grey['800']};
  `,

  footer: {
    main: css`
      position: absolute;
      bottom: 0;

      display: flex;
      justify-content: space-between;
      align-items: flex-end;

      width: calc(100% - 24px);
    `,
    left: {
      main: css`
        padding-bottom: 8px;
      `,
      gender: css`
        background: ${palette.badges.blue};
        border-radius: 6px;

        padding: 3px 7px;

        font-weight: 400;
        font-size: 1.0rem;
        color: ${palette.common.white};
      `,
    },
    right: {
      main: css`
        text-align: right;
        
        padding-bottom: 5px;
      `,
      price: {
        main: css`
          font-style: normal;
          font-weight: normal;
          font-size: 1.3rem;
          line-height: 2.3rem;
          
          color: ${palette.common.black};
        `,
        money: css`
          font-size: 1.8rem;
        `
      },
      remarks: css`
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        line-height: 23px;
        
        color: ${palette.grey['1000']};
      `
    },
  },

  tags: {
    main: css`
      position: absolute;
      top: 17px;
      left: 9px;
      
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
    `,
    tag: css`
      display: flex;
      justify-content: center;
      align-items: center;
      
      border-radius: 5px;
      min-height: 19px;

      padding: 3px 10px;

      font-style: normal;
      font-weight: bold;
      font-size: 1.0rem;

      color: ${palette.common.white};
    `,
    onsen: css`
      background-color: ${palette.primary.main};
    `,
    quality: css`
      background-color: ${palette.badges.red};
    `
  },

  img: css`
    height: 140px;
    object-fit: cover;
    border-radius: 5px 5px 0 0;
  `,
}
