import React, { useEffect, useState } from 'react'
import { Link, graphql, useStaticQuery } from 'gatsby'

import { Autoplay, Keyboard, Mousewheel } from 'swiper'
import { Swiper, SwiperSlide, SwiperProps } from 'swiper/react'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { breakpoints } from '~/utils/styles'
import { Container } from '~/components/layout/Container'

import {
  sento__list,
  sento__list__slider,
} from './styles'

import pickup from './sento.json'

type BathhouseSummaryType = {
  id: number
  image: string
  category: string
  title: string
  address: string
  closeDay: string[]
  price: number
  genderSelection: string
  remarks: string
  qualityName: string
  isOnsen: boolean
  isSauna: boolean
}

type RegionBathhousesType = {
  region: string,
  list: BathhouseSummaryType[]
}

const REGIONS = [
  { jp: '関東', en: 'kanto' },
  { jp: '近畿', en: 'kinki' },
  { jp: '中部', en: 'chubu' },
  { jp: '東北', en: 'tohoku' },
  { jp: '中国四国', en: 'chugokushikoku' },
  { jp: '北海道', en: 'hokkaido' },
  { jp: '九州沖縄', en: 'kyushuokinawa' },
]

const SAMPLE_IMAGE = "https://firebasestorage.googleapis.com/v0/b/onsen-sento.appspot.com/o/images%2Fdefault%2Fdefault-ff.jpg?alt=media&token=77579019-1172-45ab-a372-72233cae45d8"

const SentoList: React.VFC = () => {

  const bathhousesSummary = useStaticQuery<GatsbyTypes.QueryQuery>(
    graphql`
      query Query {
        allSentoFirestoreNodeType {
          nodes {
            id
            images
            category
            name
            address {
              apartmentName
              city
              mapUrl
              latitude
              houseNumber
              townVillage
              longitude
              prefecture
            }
            business {
              closeDay
            }
            price {
              adult {
                weekday
              }
            }
            genderSelection
            oneLineRemark
            qualities {
              section
            }
            isOnsen
            man {
              saunas {
                informations {
                  name
                }
              }
            }
            woman {
              saunas {
                informations {
                  name
                }
              }
            }
          }
        }
      }
    `
  ).allSentoFirestoreNodeType.nodes
  const regionBathhousesList: RegionBathhousesType[] = pickup.map((region) => {
    return {
      region: region.region,
      list: bathhousesSummary.flatMap((bathhouse) => {
        return region.list.includes(Number(bathhouse.id))  // GraphQL登録時にid要素がstringに変換されるのでnumberに修正
          ? {
            id: bathhouse.id,
            image: bathhouse.images[0] || SAMPLE_IMAGE,
            category: bathhouse.category,
            title: bathhouse.name,
            address: `${bathhouse.address.prefecture}${bathhouse.address.city} ${bathhouse.address.townVillage}${bathhouse.address.houseNumber}`,
            closeDay: bathhouse.business.closeDay,
            price: bathhouse.price.adult.weekday,
            genderSelection: bathhouse.genderSelection,
            remarks: bathhouse.oneLineRemark,
            qualityName: bathhouse.qualities[0]?.section,
            isOnsen: bathhouse.isOnsen,
            isSauna: Boolean(bathhouse.man.saunas.informations[0]?.name || bathhouse.woman.saunas.informations[0]?.name)
          }
          : []
      })
    }
  })

  const [nowRegion, setNowRegion] = useState<string>(null)
  useEffect(() => {
    if(typeof window!=="undefined"){
      const url = new URL(window.location.href)
      const params = url.searchParams
      const regionParam = params.get("region")

      const regionParamQuery = ():string => {  
        switch(regionParam){
          case 'kinki' : return 'kinki';
          case 'chubu' : return 'chubu';
          case 'tohoku' : return 'tohoku';
          case 'chugokushikoku' : return 'chugokushikoku';
          case 'hokkaido' : return 'hokkaido';
          case 'kyushuokinawa' : return 'kyushuokinawa';
          default : return 'kanto';
        }
      }
      return(
        setNowRegion(regionParamQuery)
      )
    }
  }, [])

  const getSettings = (): SwiperProps => {
    let settings: SwiperProps = {
      modules: [Autoplay, Keyboard, Mousewheel],

      // スライドの表示枚数
      slidesPerView: 3,
      // スライド間のピクセル数
      spaceBetween: 16,

      // 自動動作の設定
      autoplay: {
        delay: 2500,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
      },

      // マウスホイールの設定
      mousewheel: true,

      // キーボード操作の設定
      keyboard: true,

      // 無限ループ
      loop: true,
      loopFillGroupWithBlank: true,

      // レスポンシブ設定
      breakpoints: {},
    }
    settings.breakpoints[breakpoints.tablet] = { slidesPerView: 3 }
    settings.breakpoints[750] = { slidesPerView: 4 }
    settings.breakpoints[breakpoints.pc] = { slidesPerView: 4 }
    settings.breakpoints[breakpoints.pc_header] = { slidesPerView: 5 }
    settings.breakpoints[1300] = { slidesPerView: 6 }
    settings.breakpoints[1500] = { slidesPerView: 7 }
    return settings
  }

  const SwiperItem = (bathhouseSummary: BathhouseSummaryType) => {
    const closeDayText = bathhouseSummary.closeDay.map((day) => {
      const days = ['月', '火', '水', '木', '金', '土', '日']
      if (days.includes(day)) return `${day}曜日`
      return day
    }).join('、')
    return (
      <Link to={'/sento/' + bathhouseSummary.id}>
        <div css={sento__list__slider.item}>
          <div css={sento__list__slider.tags.main}>
            {bathhouseSummary.isOnsen &&
            <div css={[sento__list__slider.tags.tag, sento__list__slider.tags.onsen]}>
              <p>Onsen*実施</p>
            </div>
            }
            {bathhouseSummary.qualityName &&
            <div css={[sento__list__slider.tags.tag, sento__list__slider.tags.quality]}>
              <p>{bathhouseSummary.qualityName}</p>
            </div>
            }
          </div>

          <div>
            <img src={bathhouseSummary.image} css={sento__list__slider.img} alt={bathhouseSummary.title} />
          </div>
          <div css={sento__list__slider.content}>
            <div css={sento__list__slider.category.main}>
              <p>{bathhouseSummary.category}</p>
              {bathhouseSummary.isSauna &&
              <p css={sento__list__slider.category.sauna}>サウナ</p>
              }
            </div>
            <p css={sento__list__slider.title}>{bathhouseSummary.title}</p>
            <p css={sento__list__slider.address}>{bathhouseSummary.address}</p>
            <p css={sento__list__slider.closeDay}>定休日：{closeDayText}</p>
            <div css={sento__list__slider.footer.main}>
              <div css={sento__list__slider.footer.left.main}>
                <div css={sento__list__slider.footer.left.gender}>
                  <p>{bathhouseSummary.genderSelection}</p>
                </div>
              </div>
              <div css={sento__list__slider.footer.right.main}>
                <p css={sento__list__slider.footer.right.price.main}>
                  入浴料：
                  <span css={sento__list__slider.footer.right.price.money}>￥{bathhouseSummary.price}~</span>
                </p>
                <p css={sento__list__slider.footer.right.remarks}>{bathhouseSummary.remarks}</p>
              </div>
            </div>
          </div>
        </div>
      </Link>
    )
  }


  return (
    <>
      <Container>
        <div css={sento__list.contents_text}>
          <div css={sento__list.category.main}>
            <p css={sento__list.category.text}>浴場施設データベース(β版)</p>
          </div>
          <p css={sento__list.title.main}>
            全国の温泉・銭湯を見る
          </p>
        </div>
      </Container>

      <div css={sento__list.regions}>
        {REGIONS.map((region, index) => (
          <div key={`region-${index}`}>
            {region.en === nowRegion ?
              <div
                css={[sento__list.region.main, sento__list.region.active]}
                onClick={() => {
                  setNowRegion(region.en)
                }}
              ><p>{region.jp}</p></div>
              :
              <div
                key={`region-${index}`}
                css={sento__list.region.main}
                onClick={() => {
                  setNowRegion(region.en)
                  history.replaceState({},"",`?region=${region.en}`)
                }}
              ><p>{region.jp}</p></div>
            }
          </div>
        ))}
      </div>

      <div css={sento__list__slider.wrapper}>
        {regionBathhousesList.map((bathhouses, index: number) => {
          if (bathhouses.region !== nowRegion) {
            return
          }
          // データ数がスライダーの仕様枚数に満たなかった場合にかさ増し
          while (bathhouses.list.length <= 6) {
            bathhouses.list = bathhouses.list.concat(bathhouses.list)
          }
          return (
            <Swiper
              key={`region-${index}`}
              {...getSettings()}
            >
              {
                bathhouses.list.map((bathhouse, index) => (
                  <SwiperSlide>
                    <SwiperItem key={`list-${index}`} {...bathhouse} />
                  </SwiperSlide>
                ))
              }
            </Swiper>
          )
        })}
      </div>
    </>
  )
}

export default SentoList
