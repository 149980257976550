import { css } from '@emotion/core'
import { palette } from '~/utils/colors'

export const container = css`
	display: flex;

	a {
		width: 50%;
	}
`

export const btn = css`
	color: ${palette.primary.main};
	padding: 13px;
	line-height: 18px;
`

export const left_btn = css`
	border-radius: 22px 0 0 22px;
	color: ${palette.common.white};
	background-color: ${palette.primary.main};

	:hover {
		background-color: ${palette.primary.light};
	}
`

export const right_btn = css`
	border-radius: 0 22px 22px 0;
`