import { css } from '@emotion/core'
import { palette } from '~/utils/colors'
import { breakpoints } from '~/utils/styles'

export const pc_hide = css`
	@media (min-width: ${breakpoints.pc}px) {
		display: none;
	}
`

export const sp_hide = css`
	@media (orientation: portrait) {
		display: none;
	}
`

export const container = css`
	padding: 0 20px;
`

export const section = css`
	padding: 40px 0;
	border-top: 1px solid ${palette.grey['500']};
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 80px;
		padding-bottom: 80px;
	}
`

export const header_bottom_btn = css`
	display: flex;
	@media (min-width: ${breakpoints.tablet}px) {
		display: none;
	}
`

export const double_btn = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		width: 50%;
		margin-top: 40px;
		margin-left: auto;
		margin-right: auto;
	}
`

export const feature_icon_section = css`
	margin-top: 20px;
	padding: 20px;
	background-color: ${palette.grey['500']};
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 90px;
		padding: 40px;
	}
`

export const feature_icon_list = css`
	display: flex;
	justify-content: space-between;
	@media (min-width: ${breakpoints.pc}px) {
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
		padding-left: 0;
		padding-right: 0;
	}
`

export const feature_icon_card = css`
	width: calc(92% / 3);
	text-align: center;
`

export const feature_icon_img_box = css`
	width: 40px;
	margin: 0 auto;
	@media (min-width: ${breakpoints.tablet}px) {
		width: 60px;
	}
`

export const feature_icon_text = css`
	margin-top: 10px;
	font-size: 1.2rem;
	font-weight: bold;
	@media (min-width: ${breakpoints.pc}px) {
		font-size: 1.6rem;
	}
`

export const contents_bg = css`
	overflow: hidden;
	background-size: cover;
	background-position: center center;
	width: calc(100% - 20px);
	height: 50vw;
	border-radius: 0 20px 20px 0;
	position: relative;

	@media (min-width: ${breakpoints.tablet}px) {
		width: 50%;
		min-width: 50%;
		height: auto
	}
	@media (min-width: ${breakpoints.pc}px) {
		width: calc(100% - 500px);
	}
`

export const contents_bg_2 = css`
	${contents_bg};
	border-radius: 20px 0 0 20px;
	margin: 0 0 0 auto;
`

export const contents_text = css`
	padding: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		min-width: 360px;
		padding: 0 40px;
	}
	@media (min-width: ${breakpoints.pc}px) {
		width: 500px;
	}
`

export const contents_description = css`
	margin-top: 10px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 20px;
	}
`

export const text_link = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
	}
`

export const category = css`
	margin-bottom: 10px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-bottom: 20px;
	}
`

export const description_box = css`
	margin-top: 20px;
	padding: 20px;
	border-radius: 8px;
	background-color: ${palette.grey['500']};
`

export const tags = css`
	margin-top: 20px;
	span {
		margin-right: 10px;
	}
`

export const description_text = css`
	padding-left: 1em;
	text-indent: -1em;
	font-weight: normal;

	:before {
		font-weight: normal;
	}

	margin-top: 10px;
`

export const popup = css`
	position: absolute;
	right: 20px;
	top: -20px;
	@media (min-width: ${breakpoints.pc}px) {
		right: 20%;
	}
`

export const supervisor = css`
	padding-left: 20px;
	padding-right: 20px;
	background-color: ${palette.grey['500']};
`

export const supervisor_contents_box = css`
	@media (min-width: ${breakpoints.pc}px) {
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
		padding-left: 0;
		padding-right: 0;
	}
`

export const list_x_scroll = css`
	position: relative;
	display: flex;
	overflow-x: scroll;
	margin-top: 20px;
	padding-left: 20px;
	:after {
		min-width: 20px;
		height: 1px;
		content: '';
	}
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
		padding-left: 40px;
		padding-right: 40px;
		:after {
			min-width: 40px;
		}
	}
	@media (min-width: ${breakpoints.pc}px) {
		justify-content: center;
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
		padding-left: 0;
		padding-right: 0;
		:after {
			display: none;
		}
	}
`

export const voice_title = css`
	margin-top: 10px;
`

export const heading_box = css`
	position: relative;
	padding: 0 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-left: 40px;
		padding-right: 40px;
		text-align: center;
	}
	@media (min-width: ${breakpoints.pc}px) {
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
		padding-left: 0;
		padding-right: 0;
	}
`

export const news = css`
	padding-top: 20px;
	padding-bottom: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-top: 20px;
		padding-bottom: 20px;
	}
`

export const bg_primary = css`
 	background-color: ${palette.primary.main};
 `

export const news_heading_box = css`
	margin-top: 10px;
	${heading_box};
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	padding: 0;
	@media (min-width: ${breakpoints.tablet}px) {
		display: block;
		position: relative;
		padding-left: 0;
		padding-right: 0;
	}
`

export const news_text_link = css`
	@media (min-width: ${breakpoints.tablet}px) {
		position: absolute;
		right: 0;
		bottom: 0;
	}
`

export const news_card = css`
	margin-right: 10px;
	width: calc(100% - 40px);
	@media (min-width: ${breakpoints.tablet}px) {
		min-width: calc(50% - 10px);
		width: calc(50% - 10px);
	}
	@media (min-width: ${breakpoints.pc}px) {
		min-width: auto;
		width: 334px;
	}
`

export const twitter_card = css`
	width: calc(100% - 45px);
	min-width: calc(100% - 45px);
	@media (min-width: ${breakpoints.tablet}px) {
		min-width: calc(50% - 10px);
		width: calc(50% - 10px);
	}
	@media (min-width: ${breakpoints.pc}px) {
		min-width: auto;
		width: 334px;
		align-self: flex-start;
	}
`

export const twitter_card_right = css`
	${twitter_card};
	margin-left: 10px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-left: 20px;
	}
	@media (min-width: ${breakpoints.pc}px) {
		margin-left: 40px;
	}
`

export const story_title = css`
	margin-top: 10px;
`

export const story_body = css`
	margin-top: 20px;
	padding: 0 20px;
`

export const story_blur = css`
	:before {
		content: "";
		display: block;
		width: 100%;
		height: 100%;
		position: absolute;
		top: 0;
		left: 0;
		background: linear-gradient(to bottom, rgba(240, 243, 245, 1) 0%, rgba(240, 243, 245, 1) 20%, rgba(255, 255, 255, .6) 100%);
		filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#f0f3f5', endColorstr='#00ffffff', GradientType=0);
	}
`

export const story_section = css`
	position: relative;
	background-size: cover;
	background-position: center center;

	* {
		position: relative;
		z-index: 1;
	}

	@media (min-width: ${breakpoints.tablet}px) {
		text-align: center;
	}
`

export const story_link = css`
	margin-top: 40px;
	@media (min-width: ${breakpoints.tablet}px) {
		width: 200px;
		padding: 14px 20px;
		margin-left: auto;
		margin-right: auto;
	}
`

export const gallery__title = css`
	margin-top: 10px;
`

export const gallery_img = css`
	width: 100%;
	margin-right: 10px;

	@media (min-width: ${breakpoints.tablet}px) {
		margin-right: 0px;
		margin-left: 20px;
	}

	div {
		width: calc(50vw - 25px);
		@media (min-width: ${breakpoints.tablet}px) {
			width: 100%;
		}
	}


	li {
		margin-top: 10px;
		@media (min-width: ${breakpoints.tablet}px) {
			margin-top: 20px;
		}
	}

	img {
		border-radius: 12px;
		width: calc(50vw - 25px);
		height: calc(50vw - 25px);
		object-fit: cover;
		@media (min-width: ${breakpoints.tablet}px) {
			width: 100%;
			height: auto;
		}
	}
`

export const img = css`
	border-radius: 12px;
	width: calc(50vw - 25px);
	height: calc(50vw - 25px);
	object-fit: cover;
	@media (min-width: ${breakpoints.tablet}px) {
		width: 100%;
		height: auto;
	}
`

export const list__yx = css`
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
		overflow: hidden;
		padding-left: 40px;
		padding-right: 40px;
		display: flex;
		justify-content: space-between;
	}
	@media (min-width: ${breakpoints.pc}px) {
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
		margin-top: 40px;
		overflow: hidden;
		padding-left: 0px;
		padding-right: 0px;
		display: flex;
		justify-content: center;
	}
`

export const card_l = css`
	width: calc(100% - 40px);
	min-width: calc(100% - 40px);
	margin-top: 20px;
	margin-right: auto;
	margin-left: auto;
	align-self: flex-start;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;
		width: calc(50% - 10px);
		min-width: auto;
	}
`

export const card_l_left = css`
	${card_l};
	@media (min-width: ${breakpoints.pc}px) {
		margin-left: 40px;
	}
`

export const service_list = css`
	background-color: ${palette.primary.main};
	padding: 40px 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding-left: 40px;
		padding-right: 40px;
	}
	@media (min-width: ${breakpoints.pc}px) {
		padding: 80px 0;
		margin-left: auto;
		margin-right: auto;
		max-width: 1000px;
	}
`

export const cp_box = css`
	@media (min-width: ${breakpoints.tablet}px) {
		padding-left: 40px;
		padding-right: 40px;
	}
`

export const cp_card = css`
	width: calc(100% - 40px);
	min-width: calc(100vw - 40px);
	margin-left: auto;
	margin-right: auto;
	@media (min-width: ${breakpoints.tablet}px) {
		width: 100%;
		min-width: 100%;
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
	}
`

export const support = css`
	background-color: ${palette.grey['500']};
`

export const support_body = css`
	margin-top: 20px;
`

export const contents_box = css`
	padding: 0 20px;
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 0;
	}
`

export const cp_list = css`
	max-width: 1000px;
	margin-left: auto;
	margin-right: auto;
	overflow: hidden;
	padding-left: 0;
	padding-right: 0;
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 40px;
	}
`

export const card_xl = css`
	margin-top: 20px;
	position: relative;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-left: 0;
		margin-right: auto;
	}
	@media (min-width: ${breakpoints.pc}px) {
		width: 92%;
	}
`

export const card_xl_even = css`
	${card_xl};
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 80px;
		margin-left: auto;
		margin-right: 0;
	}
`

export const column2 = css`
	@media (min-width: ${breakpoints.tablet}px) {
		display: flex;
	}
`

export const column2_reverse = css`
	${column2};
	@media (min-width: ${breakpoints.tablet}px) {
		flex-direction: row-reverse;
	}
`

export const sns_section_title = css`
	margin-top: 10px;
`

export const sns_card = css`
	margin-top: 40px;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-top: 0;
		width: calc(50% - 10px);
		min-width: calc(50% - 10px);
	}
	@media (min-width: ${breakpoints.pc}px) {
		margin-top: 0;
		width: 334px;
		min-width: auto;
	}
`

export const sns_card_left = css`
	${sns_card};
	@media (min-width: ${breakpoints.tablet}px) {
		margin-left: 40px;
	}
`

export const sns_heading_box = css`
	${heading_box};
	display: flex;
	align-items: flex-end;
	justify-content: space-between;
	@media (min-width: ${breakpoints.tablet}px) {
		display: block;
		position: relative;
		padding-left: 0;
		padding-right: 0;
	}
`

export const sns_title = css`
	text-align: left;
`

export const sns_link = css`
	@media (min-width: ${breakpoints.tablet}px) {
		position: absolute;
		right: 0;
		bottom: 0;
	}
`

export const amazon_link = css`
	:after {
		position: absolute;
		right: 12px;
	}
`

export const media_list = css`
	padding: 0 20px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	flex-wrap: wrap;
	@media (min-width: ${breakpoints.tablet}px) {
		padding: 0 40px;
	}
	@media (min-width: ${breakpoints.pc}px) {
		max-width: 1000px;
		margin-left: auto;
		margin-right: auto;
		overflow: hidden;
		padding-left: 0;
		padding-right: 0;
	}
`

export const media_logo = css`
	margin-top: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		width: calc(20% - 10px);
		margin-top: 20px;
	}
`

export const media_logo_l = css`
	${media_logo};
	width: 100%;
`
export const media_logo_m = css`
	${media_logo};
	width: calc(50% - 10px);
`

export const icon_button = css`
	margin-top: auto;
	padding: 10px 12px;
	min-height: 32px;
	font-size: 1.2rem;
`

export const hero_container = css`
	position: relative;
`

export const slider = css`
	margin-left: 40px;
	overflow: hidden;
	@media (min-width: ${breakpoints.tablet}px) {
		margin-left: 288px;
	}
`

export const slider_item = css`
	width: 100%;
	height: 524px;
	overflow: hidden;
	border-bottom-left-radius: 40px;
	background: linear-gradient(270deg, rgba(0, 0, 0, 0.24) 0%, rgba(255, 255, 255, 0.4) 100%);
	background-blend-mode: lighten;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
	@media (min-width: ${breakpoints.tablet}px) {
		height: 992px;
		border-bottom-left-radius: 80px;
	}
`

export const hero_top_left = css`
	position: absolute;
	top: 40px;
	left: 20px;
	@media (min-width: ${breakpoints.tablet}px) {
		top: 80px;
		left: 80px;
	}
`

export const hero_button = css`
	visibility: hidden;
	@media (min-width: ${breakpoints.tablet}px) {
		visibility: visible;
		margin-top: 40px;
		width: 384px;
	}

`

export const hero_top_right = css`
	position: absolute;
	top: 40px;
	right: 40px;
	@media (min-width: ${breakpoints.tablet}px) {
		top: 80px;
		right: 100px;
	}
`

export const hero_bottom_left = css`
	position: absolute;
	bottom: 22px;
	left: 20px;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	@media (min-width: ${breakpoints.tablet}px) {
		left: 80px;
	}
`

export const hero_title = css`
	font-size: 3.75rem;
	line-height: 156%;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 5.6rem;
	}
`

export const hero_subtitle = css`
	writing-mode: vertical-rl;
	letter-spacing: .1rem;
	color: ${palette.common.white};
	font-size: 2rem;
	@media (min-width: ${breakpoints.tablet}px) {
		font-size: 3.2rem;
	}
`

export const hero_tag = css`
	margin-top: 10px;
`

export const text_shadow = css`
	text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
`

export const sento = css`
	overflow: hidden;
`
